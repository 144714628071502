import React from "react"
import { Link, navigate } from "gatsby"

import { handleSignup, sendVerificationEmail } from "../../../services/auth"

class Signup extends React.Component {
  state = {
    email: ``,
    password: ``,
    password2: ``,
  }

  verifyPassword = () => {
    // TODO: Move to a service, because it's also used when changing a password (ChangePassword.js)
    const passwordValidation = { valid: false, message: '' }
    if (!this.state.password || !this.state.password2) {
      passwordValidation.message = 'You must type a password into the \'password\' and \'retype password\' fields'
      return passwordValidation;
    }
    if (this.state.password !== this.state.password2) {
      passwordValidation.message = 'Both password fields must be exactly the same'
      return passwordValidation;
    }
    passwordValidation.valid = true
    return passwordValidation
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleCheckboxUpdate = event => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.state.policy) {
      return alert('You must accept the privacy policy to continue')
    }

    const passwordValidation = this.verifyPassword()
    if (!passwordValidation.valid) {
      alert(passwordValidation.message)
    } else {
      handleSignup(this.state)
        .then(() => {
          sendVerificationEmail()
          navigate(`/account/verify`)
        })
        .catch(err => alert(err.message))
    }
  }

  render() {
    return (
      <>
        <h3>Create an account</h3>
        <p>You are one step closer to promoting your brand. Sign up with your email address below to edit your kite school details.</p>
        <p>Already have an account? <Link to="/account/login">Click here to sign in</Link>.</p>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input type="email" name="email" onChange={this.handleUpdate} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password2">Retype password</label>
            <input
              type="password"
              name="password2"
              onChange={this.handleUpdate}
            />
          </div>

          <div className="form-group form-checkbox-group">
            <input
              id="policy"
              type="checkbox"
              name="policy"
              onChange={this.handleCheckboxUpdate}
            />
            <label htmlFor="policy">I have read and agree to the <Link to="/privacy-policy">privacy policy</Link></label>
          </div>

          <button type="submit" disabled={!this.state.email || !this.state.policy}>Create account</button>
        </form>
      </>
    )
  }
}

export default Signup
